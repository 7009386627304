<template>
  <b-row class="mt-1">
    <b-col>
      <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit">
        <feather-icon icon="SaveIcon" size="16"/>
        <span class="align-middle"> Save</span>
      </b-button>
      <b-button
          :to="{ name: backRoute }"
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'">
        <feather-icon icon="XCircleIcon" size="16"/>
        <span class="align-middle" role="button"> Cancel</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {BButton, BCol, BRow} from "bootstrap-vue";

export default {
  components: {BButton, BCol, BRow},
  props:['backRoute']
}
</script>
